import React from 'react';

const Api = () => {
  return (
    <div>
      API
    </div>
  );
}

export default Api;
