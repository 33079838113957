import React from 'react';

const Sms = () => {
  return (
    <div>
      SMS
    </div>
  );
}

export default Sms;
