import React from 'react';

const Webhook = () => {
  return (
    <div>
      WEB HOOK
    </div>
  );
}

export default Webhook;
