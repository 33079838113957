import React from 'react';

const PaymentGateway = () => {
  return (
    <div>
      PAYMENT GATEWAY
    </div>
  );
}

export default PaymentGateway;
