import React from 'react';

const EmailIntegrations = () => {
  return (
    <div>
      hi email integrations
    </div>
  );
}

export default EmailIntegrations;
